import React from 'react';
import { Link, graphql } from 'gatsby';
import type { PageProps } from 'gatsby';

import Bio from '../components/bio';
import Layout from '../components/layout';
import Seo from '../components/seo';
import type { BlogPostPageContext } from '../../gatsby-node';

type BlogPostBySlugQuery = {
    site: { siteMetadata: { title: string; author: string } };
    markdownRemark: {
        id: string;
        excerpt: string;
        html: string;
        frontmatter: {
            title: string;
            htmlTitle: string;
            date: any;
            description: string;
            originalPost: string;
        };
    };
};

class BlogPostTemplate extends React.Component<
    PageProps<BlogPostBySlugQuery, BlogPostPageContext>
> {
    render() {
        const post = this.props.data.markdownRemark;
        const siteTitle = this.props.data.site.siteMetadata.title;
        const { previous, next } = this.props.pageContext;

        return (
            <Layout title={siteTitle}>
                <Seo
                    title={post.frontmatter.htmlTitle || post.frontmatter.title}
                    description={post.frontmatter.description || post.excerpt}
                />
                <h1>{post.frontmatter.title}</h1>
                <p
                    style={{
                        display: `block`,
                    }}
                >
                    {post.frontmatter.date}
                </p>
                <div dangerouslySetInnerHTML={{ __html: post.html }} />
                {post.frontmatter.originalPost && (
                    <p style={{ marginTop: 20 }}>
                        This is a repost. It was originally posted{' '}
                        <a href={post.frontmatter.originalPost} rel="canonical">
                            there
                        </a>
                        .
                    </p>
                )}
                <hr />
                <Bio />

                <ul
                    style={{
                        display: `flex`,
                        flexWrap: `wrap`,
                        justifyContent: `space-between`,
                        listStyle: `none`,
                        padding: 0,
                    }}
                >
                    <li>
                        {previous && (
                            <Link to={previous.fields.slug} rel="prev">
                                ← {previous.frontmatter.title}
                            </Link>
                        )}
                    </li>
                    <li>
                        {next && (
                            <Link to={next.fields.slug} rel="next">
                                {next.frontmatter.title} →
                            </Link>
                        )}
                    </li>
                </ul>
            </Layout>
        );
    }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
    query BlogPostBySlug($slug: String!) {
        site {
            siteMetadata {
                title
                author
            }
        }
        markdownRemark(fields: { slug: { eq: $slug } }) {
            id
            excerpt(pruneLength: 160)
            html
            frontmatter {
                title
                htmlTitle
                date(formatString: "MMMM DD, YYYY")
                description
                originalPost
            }
        }
    }
`;
